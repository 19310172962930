.dialog-content-wrap .el-dialog__header {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: left;
  border-bottom: 1px  solid #e6e6e6;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px 0px;
  color: #333333;
  font-size: 16px;
}
.dialog-content-wrap .el-dialog__header .el-dialog__headerbtn {
    top: 16px;
}
.dialog-content-wrap .el-dialog__body {
  padding: 40px 50px 30px 50px;
}
.dialog-content-wrap .el-dialog__body .content-wrap {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #333333;
    text-align: center;
    padding-bottom: 30px;
}
.dialog-content-wrap .el-dialog__body .content-wrap .title-content-wrap {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
}
.dialog-content-wrap .el-dialog__body .content-wrap .desc-content-wrap {
      font-size: 14px;
      color: #E81F1F;
      margin-top: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
}
.dialog-content-wrap .el-dialog__body .button-group-wrap .button-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 14px;
    font-family: MicrosoftYaHei;
}
.dialog-content-wrap .el-dialog__body .button-group-wrap .button-group .el-button {
      min-width: 90px;
      height: 36px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      border-radius: 4px;
      padding: 0px 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.dialog-content-wrap .el-dialog__body .button-group-wrap .button-group .cancel-button {
      background: #FFFFFF;
      color: #409EFF;
      border: 1px solid #409eff;
      margin-right: 20px;
}
.dialog-content-wrap .el-dialog__body .button-group-wrap .button-group .right-button {
      background: #409EFF;
      color: #FFFFFF;
}
