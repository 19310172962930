@charset "UTF-8";
.training .sider-opertion {
  z-index: 10;
  position: absolute;
  top: 0;
  left: -360px;
  padding-top: 38px;
  height: 100%;
  background: white;
  width: 360px;
  background: rgba(36, 36, 36, 0.5);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.training .sider-opertion .put {
    position: absolute;
    width: 48px;
    height: 48px;
    line-height: 48px;
    background-color: rgba(0, 0, 0, 0.6);
    right: -48px;
    cursor: pointer;
    border: 1px solid #666666;
    font-size: 14px;
    font-weight: 400;
    color: white;
}
.training .sider-opertion-put {
  -webkit-animation: isput 0.5s;
          animation: isput 0.5s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.training .sider-opertion-open {
  -webkit-animation: isopen 0.5s;
          animation: isopen 0.5s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.training .sider-opertion-open.driver-fix-stacking .tabs-wap.driver-highlighted-element {
  color: black;
}
.training .left-side {
  width: 360px;
}
.training .left-side .btns {
    text-align: left;
    margin-left: 20px;
}
.training .left-side .btns button {
      background: #409eff;
}
.training .left-side .btns button:hover {
        background: #66b1ff;
        border-color: #66b1ff;
}
.training .left-side .list-wrap {
    height: 85%;
    background: transparent;
    padding: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    text-align: left;
}
.training .left-side .list-wrap .tabs-wap {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      color: #ffffff;
}
.training .left-side .list-wrap .tabs-wap li {
        font-size: 14px;
        margin-right: 24px;
        padding-bottom: 10px;
}
.training .left-side .list-wrap .tabs-wap li:hover {
          cursor: pointer;
          color: #409eff;
}
.training .left-side .list-wrap .tabs-wap .active {
        color: #409eff;
        border-bottom: 2px solid #409eff;
}
.training .left-side .list-wrap .course-list-cont {
      font-size: 14px;
      line-height: 26px;
      margin-top: 20px;
}
.training .left-side .list-wrap .course-list-cont .el-tree {
        background: transparent;
        color: white;
}
.training .left-side .list-wrap .course-list-cont .course-tree-box.el-tree.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
        background-color: transparent;
}
.training .left-side .list-wrap .course-list-cont .course-tree-box.el-tree > .el-tree-node > .el-tree-node__content {
        font-weight: 600;
        background-color: transparent;
}
.training .left-side .list-wrap .course-list-cont .course-tree-box.el-tree .el-tree-node__content:hover {
        background-color: transparent;
}
.training .left-side .list-wrap .course-list-cont .course-tree-box.el-tree .el-tree-node__content > .el-tree-node__expand-icon {
        padding: 12px;
}
.training .left-side .list-wrap .course-list-cont .course-tree-box.el-tree .is-leaf.el-icon-caret-right:before {
        content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAKtJREFUeNpifPbsmScDA8NcIJZkoAw8B+JkRqCBz4CMMCA+giwLEcYPpKSkkLk2QLwKZOB/IIMRXTEZBoLAfyYivLISiMWI9TcxBoKC4zIQh1LLQAaoC1dBsRg1DISBUEKuZSIjeTBii0QYYCHRsDVAnA3Eryh14WsgDod69RU+hSzUcBWpBoaSEiYs0Dxog571sOQCQsAaiF+Asp4XtHCQoLBweArEaQABBgC7YinND0Nz6gAAAABJRU5ErkJggg==);
}
.training .left-side .list-wrap .course-list-cont .course-tree-box.el-tree .custom-tree-node-finished {
        color: #47bc9b;
}
.training .left-side .list-wrap .course-list-cont .course-tree-box.el-tree .custom-tree-node-finished:hover {
          color: #409eff;
}
.training .left-side .list-wrap .course-list-cont .course-tree-box.el-tree .custom-tree-node-selected {
        color: #409eff;
}
.training .left-side .list-wrap .course-list-cont .course-tree-box.el-tree .custom-tree-node:hover {
        color: #409eff;
}
.training .left-side .list-wrap .course-list-cont h4 {
        font-size: 16px;
        margin: 0;
}
.training .left-side .list-wrap .course-list-cont .item-list li {
        color: #333333;
}
.training .left-side .list-wrap .course-list-cont .item-list li i {
          margin-right: 10px;
          color: #cccccc;
}
.training .left-side .list-wrap .sign-list-wrap {
      height: 450px;
      color: white;
}
.training .left-side .list-wrap .sign-list-wrap .qrcode-box {
        margin-bottom: 30px;
}
.training .left-side .list-wrap .sign-list-wrap .qrcode-close {
        padding: 20px 0 0 0;
        margin: 0 auto;
        width: 80px;
}
.training .left-side .list-wrap .sign-list-wrap .qrcode-close button {
          background: #409eff;
}
.training .left-side .list-wrap .sign-list-wrap .qrcode-close button:hover {
            background: #66b1ff;
            border-color: #66b1ff;
}
.training .left-side .list-wrap .sign-list-wrap .no-data-box {
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: #eb2121;
}
.training .left-side .list-wrap .sign-list-wrap .sign-type-title {
        margin-top: 10px;
        font-size: 14px;
        font-weight: bolder;
        color: white;
}
.training .left-side .list-wrap .sign-list-wrap .sign-list {
        border-top: 1px solid rgba(66, 66, 66, 0.4);
        margin-top: 10px;
}
.training .left-side .list-wrap .sign-list-wrap .sign-list li {
          border-bottom: 1px solid rgba(66, 66, 66, 0.4);
          font-size: 14px;
          line-height: 28px;
          color: white;
          padding: 10px 0;
}
.training .left-side .list-wrap .sign-list-wrap .sign-list li .top-info {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
}
.training .left-side .list-wrap .sign-list-wrap .sign-list li .top-info .name {
              width: 80px;
}
.training .left-side .list-wrap .sign-list-wrap .sign-list li .top-info .idcard {
              width: 160px;
}
.training .left-side .list-wrap .sign-list-wrap .sign-list li .top-info .type {
              text-overflow: ellipsis;
              -webkit-box-flex: 1;
                  -ms-flex: 1;
                      flex: 1;
              overflow: hidden;
              white-space: nowrap;
}
.training .left-side .list-wrap .sign-list-wrap .sign-list li .bot-info {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
}
.training .left-side .list-wrap .sign-list-wrap .pagination-wrap {
        text-align: center;
        margin-top: 30px;
}
.training .left-side .list-wrap .sign-list-wrap .pagination-wrap button {
          background: transparent;
}
.training .left-side .list-wrap .sign-list-wrap .pagination-wrap .el-pager li {
          background: transparent;
}
.training .meeting-side {
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  padding-top: 0px;
}
.training .meeting-side .meeting-title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: white;
    padding: 10px 20px;
    text-align: left;
    background: #000000;
}
.training .meeting-side .m-meeting-box {
    color: white;
}
.training .meeting-side .m-meeting-box .bd-operation-group {
      margin-top: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
}
.training .meeting-side .m-meeting-box .bd-operation-group .el-button {
        width: 85px;
        height: 24px;
        border: 1px solid #ffffff;
        border-radius: 12px;
        padding: 0;
        line-height: 1px;
        background-color: transparent;
        color: white;
}
.training .meeting-side .m-meeting-box .bd-operation-group .el-button:focus,
      .training .meeting-side .m-meeting-box .bd-operation-group .el-button:hover {
        color: #409eff;
        background-color: transparent;
        border-color: #409eff;
}
.training .meeting-side .m-meeting-box .bd-operation-group .checked {
        color: #409eff;
        background-color: transparent;
        border-color: #409eff;
}
.training .meeting-side .m-meeting-box .bd-list-group {
      margin-top: 20px;
}
.training .meeting-side .m-meeting-box .bd-list-group .cell-item {
        margin-bottom: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        overflow: hidden;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        cursor: pointer;
}
.training .meeting-side .m-meeting-box .bd-list-group .cell-item img {
          margin-right: 10px;
}
.training .meeting-side .m-meeting-box .bd-list-group .cell-item span {
          white-space: nowrap;
          /* 防止文本换行 */
          overflow: hidden;
          /* 隐藏溢出的内容 */
          text-overflow: ellipsis;
          /* 显示省略号 */
}
.training .meeting-side .m-meeting-box .bd-list-group .cell-item-checked {
        color: #409eff;
}
.training .meeting-side .m-meeting-box .bd-list-group .cell-item:hover {
        color: #409eff;
}
.training .right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}
.training .right .meeting-time {
    position: absolute;
    left: 50%;
    top: 55px;
    z-index: 100;
    width: 150px;
    height: 48px;
    background: #f2af32;
    border-radius: 8px;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 48px;
    text-align: center;
    margin-left: 175px;
}
.training .right .total {
    color: #fc9e25;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.training .right .total .total-right {
      cursor: pointer;
      -webkit-box-flex: 2;
          -ms-flex-positive: 2;
              flex-grow: 2;
      text-align: right;
}
.training .right .content-main {
    width: 100%;
    background-color: white;
    border: 1px solid #e6e6e6;
    height: 665px;
    margin-top: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: relative;
}
.training .right .info-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    height: 675px;
    padding: 20px 360px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow-y: auto;
    background-color: white;
}
.training .right .doc-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    height: 675px;
    overflow-y: auto;
    background-color: white;
}
.training .right .photo-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    height: 675px;
    overflow-y: auto;
    text-align: center;
    line-height: 665px;
    background-color: white;
}
.training .right .video-box {
    margin-top: 10px;
    height: 665px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.training .right .video-box .video-header-box {
      position: relative;
      background-color: rgba(51, 51, 51, 0.4);
}
.training .right .video-box .video-header-box .video-title {
        color: white;
        text-align: left;
        height: 36px;
        line-height: 36px;
        text-indent: 6px;
        margin-left: 15px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
}
.training .right .video-box .video-header-box .study-timmer-box {
        background-color: #f2af32;
        height: 48px;
        line-height: 48px;
        width: 150px;
        border-radius: 8px;
        color: #ffffff;
        font-weight: bolder;
        font-size: 32px;
        position: absolute;
        z-index: 2;
        top: -1px;
        left: 60%;
}
.training .right .video-box .video-content-box {
      position: relative;
      height: 665px;
      margin: auto;
}
.training .right .video-box .video-content-box #videoBarrage {
        position: absolute;
        background-color: black;
        width: 100%;
        height: 100%;
        outline: 1px solid #eee;
}
.training .right .video1 {
    height: 100%;
}
.training .right .tc-video-player {
    height: 665px !important;
}
.training .right .tc-video-player .vjs-poster {
      background-size: 100%;
}
.training .right .tc-video-player .vjs-switch {
      line-height: 3em;
      margin-left: 1em;
      margin-right: 1em;
}
.training .right .tc-video-player .vjs-audio-switch {
      width: 7rem;
      line-height: 3em;
      margin-left: 1em;
}
.training .right .tc-video-player .vjs-audio-switch .el-switch__label {
        color: white;
}
.training .right .tc-video-player .vjs-setting {
      line-height: 3em;
      margin-left: 1em;
}
.training .right .tc-video-player .vjs-setting .setting-modal {
        position: absolute;
        width: 280px;
        height: 260px;
        top: -260px;
        left: -200px;
        background: rgba(0, 0, 0, 0.6);
        padding: 20px;
}
.training .right .tc-video-player .vjs-setting .setting-img {
        line-height: 4em;
        height: 3em;
}
.training .right .tc-video-player .vjs-setting .setting-img img {
          -webkit-transition: all 0.1s;
          transition: all 0.1s;
}
.training .right .tc-video-player .vjs-setting .setting-img img:hover {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg);
}
.training .right .tc-video-player .vjs-control-bar {
      z-index: 20 !important;
}
.training .right .tc-video-player .baberrage-stage {
      padding-top: 45px;
}
.training .video-play-gary-bg {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 360px;
  top: 123px;
  width: 74%;
  height: 100%;
  z-index: 2005;
  top: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.training .video-play-gary-bg .alert-center-wrap {
    display: block;
    background-color: white;
    width: 350px;
    height: 180px;
}
.training .video-play-gary-bg .alert-center-wrap .title-alert {
      text-align: left;
      padding: 30px 20px 10px 20px;
      font-size: 16px;
      font-weight: bold;
}
.training .video-play-gary-bg .alert-center-wrap .detail-alert {
      text-align: left;
      padding: 0px 20px 30px 20px;
      font-size: 14px;
      color: #666666;
}
.training .video-play-gary-bg .alert-center-wrap .button-alert {
      margin-right: 20px;
      float: right;
}
.training .video-play-gary-bg .alert-center-wrap .clear-alert {
      clear: both;
}
.training .video-play-gary-bg .lert-center-dis-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 0px;
}
.training .baber-rage-stage-group {
  position: absolute;
  top: 0;
  left: 0;
}
.training .baber-rage-stage-group .baberrage-lane {
    height: 80px;
}
.training .baber-rage-stage-group .normal {
    font-size: 20px;
}
.training .baber-rage-stage-group .baberrage-avatar {
    width: 50px;
    height: 50px;
}
.training .baber-rage-stage-group .baberrage-msg {
    line-height: 50px;
}
.training .baber-rage-stage-group .baberrage-avatar img {
    width: 50px;
    height: 50px;
}
.training .meeting-baber-rage-stage-group {
  padding-top: 60px;
}
.training .meeting-tcp-player .vjs-fullscreen-control {
  display: none !important;
}
.qrcode-wrap-enlargeQR {
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.qrcode-wrap {
  text-align: center;
  margin-top: 10px;
}
.qrcode-wrap img {
    display: inline-block;
}
.qrcode-wrap p {
    line-height: 30px;
    font-size: 14px;
}
.qrcode-wrap .qrcode-optering {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 245px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.5);
    height: 35px;
    padding: 0 10px;
    color: white;
}
.qrcode-wrap .qrcode-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.qrcode-wrap .qrcode-img .qrcode_button {
      line-height: 20px;
      height: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.qrcode-wrap .qrcode-img > div {
      margin-right: 10px;
      cursor: pointer;
}
.qrcode-wrap .img-close {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    margin-right: 5px;
}
.qrcode-wrap .img-enlarge {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    margin-right: 5px;
}
.scale-qr-box {
  z-index: 101;
  position: absolute;
  top: 100px;
  left: 0;
}
.container-wrap {
  width: 99%;
}
.dan .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

/*打开时文字位置设置*/
.dan .el-switch__label--right {
  z-index: 1;
  right: 20px;
}

/*关闭时文字位置设置*/
.dan .el-switch__label--left {
  z-index: 1;
  left: 19px;
}

/*显示文字*/
.dan .el-switch__label.is-active {
  display: block;
}
.dan.is-checked .el-switch__core:after {
  background-color: #fff;
}
.dan .el-switch__core:after {
  background-color: black;
}
@-webkit-keyframes isput {
from {
    left: 0px;
}
to {
    left: -360px;
}
}
@keyframes isput {
from {
    left: 0px;
}
to {
    left: -360px;
}
}
@-webkit-keyframes isopen {
from {
    left: -360px;
}
to {
    left: 0px;
}
}
@keyframes isopen {
from {
    left: -360px;
}
to {
    left: 0px;
}
}
video::-webkit-media-controls {
  display: none !important;
}
