.demonstration {
  font-size: 12px;
  height: 15px;
  line-height: 15px;
  text-align: left;
  margin-bottom: 2px;
}
.el-checkbox-group,
.el-slider {
  height: 20px;
  line-height: 20px !important;
  margin-bottom: 10px;
}
.auto-height {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
  height: auto;
}
.el-checkbox-group {
  text-align: left;
}
.el-slider__runway {
  margin: 10px 0;
}
.el-checkbox {
  margin-right: 12px;
}
.el-checkbox .el-checkbox__label {
    font-size: 12px;
    color: #cccccc;
}
