.pdf-preview-wrapper {
  position: relative;
}
.pdf-preview-wrapper .p-hd-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
}
.pdf-preview-wrapper .p-hd-bar:hover {
      background-color: black;
      color: white;
      -webkit-box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px white, 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
              box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px white, 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}
.pdf-preview-wrapper .p-hd-bar .hd-left-tip {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      font-size: 16px;
      margin-left: 40px;
      color: red;
}
.pdf-preview-wrapper .p-hd-bar .hd-left-tip .down-tip {
        color: #409eff;
        font-weight: bold;
        cursor: pointer;
}
.pdf-preview-wrapper .p-hd-bar .hd-left-tip .down-tip:hover {
          text-decoration: underline;
}
.pdf-preview-wrapper .p-hd-bar .hd-center-group {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      text-align: center;
      font-size: 16px;
      color: #333333;
}
.pdf-preview-wrapper .p-hd-bar .hd-center-group .el-button:first-of-type {
        margin-right: 40px;
}
.pdf-preview-wrapper .p-hd-bar .hd-center-group .el-button:last-child {
        margin-left: 40px;
}
.pdf-preview-wrapper .p-hd-bar .hd-center-group .el-button span {
        font-weight: bold;
        font-size: 16px;
}
.pdf-preview-wrapper .p-hd-bar .hd-center-group .hd-center-number {
        color: white;
}
.pdf-preview-wrapper .p-hd-bar .hd-right-group {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      text-align: right;
      margin-right: 40px;
}
.pdf-preview-wrapper .p-hd-bar .hd-right-group .el-button {
        font-size: 16px;
        font-weight: bold;
}
